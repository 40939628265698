body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: space-between;
          align-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 50px;
  margin-top: 20px;
}
.navi {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex: 6 200px;
  flex: 6 1 200px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.header-item.name {
  -webkit-flex: 1 230px;
  flex: 1 1 230px;
  font-family: "Nunito", sans-serif;
  color: #FF9F1C;
}
.App {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  background: #0E3745;
  position: fixed;
  width: 100vw;
  height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.navi .link {
  padding: 0.3rem 1rem;
  color: #FF9F1C;
  position: relative;
  text-decoration: none;
  margin: 0 2rem;
}
.navi .link::after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #CBF3F0;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.navi .link:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

